var render = function render(){
  var _vm$board;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.board ? _c('div', [_c('v-simple-table', {
    staticClass: "v-data-table--default v-data-table--no-hover w-100"
  }, [_c('tbody', [_c('tr', [_c('th', [_c('span', {
    staticClass: "break-words"
  }, [_vm._v(_vm._s(_vm.board.subject))])])]), _c('tr', [_c('td', [_c('div', {
    staticClass: "divider-group"
  }, [_c('ul', [_c('li', [_vm._v("작성자 : " + _vm._s(_vm.board.writer.name))]), _c('li', [_vm._v("작성일 : " + _vm._s(_vm.board.createdAt.toDate()))]), _c('li', [_vm._v("조회수 : " + _vm._s(_vm.board.viewCount))])])])])]), _c('tr', [_c('td', [_c('div', {
    staticClass: "min-h-100px min-h-md-150px board-contents",
    domProps: {
      "innerHTML": _vm._s(_vm.board.content)
    }
  })])])])]), _c('comments', {
    attrs: {
      "_board": (_vm$board = _vm.board) === null || _vm$board === void 0 ? void 0 : _vm$board._id
    }
  }), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("리스트")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }