var render = function render(){
  var _vm$value, _vm$value2, _vm$value2$user, _vm$value3, _vm$value3$createdAt, _vm$value3$createdAt$, _vm$value4, _vm$value5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "grey lighten-5",
    class: {
      'light-blue lighten-5': _vm.isRouted
    }
  }, [_c('v-card', {
    ref: "comment",
    attrs: {
      "color": "transparent",
      "tile": "",
      "disabled": (_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.isDeleted
    }
  }, [_c('v-card-subtitle', [_c('v-row', {
    staticClass: "mx-n1 my-n2",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-1 font-weight-bold",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : (_vm$value2$user = _vm$value2.user) === null || _vm$value2$user === void 0 ? void 0 : _vm$value2$user.name) + " ")]), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s((_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : (_vm$value3$createdAt = _vm$value3.createdAt) === null || _vm$value3$createdAt === void 0 ? void 0 : (_vm$value3$createdAt$ = _vm$value3$createdAt.toDateTime) === null || _vm$value3$createdAt$ === void 0 ? void 0 : _vm$value3$createdAt$.call(_vm$value3$createdAt)))]), _c('v-spacer'), _vm.isLoggedIn ? [_c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "pa-1",
    attrs: {
      "x-small": "",
      "text": "",
      "tile": "",
      "color": "secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('mention', _vm.value);
      }
    }
  }, [_c('span', [_vm._v(" 답글달기 ")])])], 1)] : _vm._e(), _vm.isUser ? [_c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "pa-1",
    attrs: {
      "x-small": "",
      "text": "",
      "tile": "",
      "color": "secondary"
    },
    on: {
      "click": function ($event) {
        _vm.isEdit = !_vm.isEdit;
      }
    }
  }, [_c('span', [_vm._v(" 수정 ")])])], 1), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "pa-1",
    attrs: {
      "x-small": "",
      "text": "",
      "tile": "",
      "color": "secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('remove', _vm.value);
      }
    }
  }, [_c('span', [_vm._v(" 삭제 ")])])], 1)] : _vm._e()], 2)], 1), _c('v-card-text', [_vm.isEdit ? _c('comment-input', _vm._b({
    attrs: {
      "usesCustomActivator": "",
      "buttonText": "수정"
    },
    on: {
      "save": function (item) {
        return (_vm.$emit('save', item) || true) && (_vm.isEdit = !_vm.isEdit);
      }
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, 'comment-input', {
    isLoggedIn: _vm.isLoggedIn
  }, false)) : _vm.value.isDeleted ? [_vm._v(" 블라인드된 댓글입니다. ")] : [(_vm$value4 = _vm.value) !== null && _vm$value4 !== void 0 && _vm$value4.mention ? _c('b', {
    staticClass: "primary--text mr-1"
  }, [_vm._v(_vm._s(_vm.value.mention))]) : _vm._e(), _vm._v(" " + _vm._s((_vm$value5 = _vm.value) === null || _vm$value5 === void 0 ? void 0 : _vm$value5.content) + " ")]], 2)], 1), _c('v-divider')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }