<template>
    <li :class="{ 'light-blue lighten-5': isRouted }">
        <v-card ref="comment" color="transparent" tile :disabled="value?.isDeleted">
            <v-card-subtitle>
                <v-row class="mx-n1 my-n2" align="center">
                    <v-col cols="auto" class="pa-1 font-weight-bold">{{ name }} </v-col>
                    <v-col cols="auto" class="pa-1">{{ value?.createdAt?.toDateTime?.() }}</v-col>
                    <v-spacer />
                    <template v-if="isLoggedIn">
                        <v-col cols="auto" class="pa-1">
                            <v-btn @click="showsReply = !showsReply" x-small text tile color="secondary" class="pa-1">
                                <span> 답글달기 </span>
                            </v-btn>
                        </v-col>
                    </template>
                    <template v-if="isUser">
                        <v-col cols="auto" class="pa-1">·</v-col>
                        <v-col cols="auto" class="pa-1">
                            <v-btn @click="isEdit = !isEdit" x-small text tile color="secondary" class="pa-1">
                                <span> 수정 </span>
                            </v-btn>
                        </v-col>
                        <v-col cols="auto" class="pa-1">·</v-col>
                        <v-col cols="auto" class="pa-1">
                            <v-btn @click="$emit('remove', value)" x-small text tile color="secondary" class="pa-1">
                                <span> 삭제 </span>
                            </v-btn>
                        </v-col>
                    </template>
                </v-row>
            </v-card-subtitle>
            <v-card-text>
                <comment-input v-if="isEdit" v-model="value" v-bind="{ isLoggedIn }" usesCustomActivator buttonText="수정" @save="(item) => ($emit('save', item) || true) && (isEdit = !isEdit)" />
                <template v-else-if="value.isDeleted"> 블라인드된 댓글입니다. </template>
                <template v-else>
                    <b v-if="value?.mention" class="primary--text mr-1">{{ value.mention }}</b>
                    {{ value?.content }}
                </template>
            </v-card-text>
        </v-card>
        <v-divider />
        <ul>
            <comment-reply v-for="item in value?.children || []" :key="item._id" :value="item" @mention="onMention" @save="(item) => $emit('save', item)" @remove="(item) => $emit('remove', item)" />
        </ul>

        <v-expand-transition>
            <div v-if="showsReply">
                <div class="pa-3">
                    <comment-input v-if="isLoggedIn" v-bind="{ isLoggedIn }" ref="comment-input" relative @save="(item) => $emit('save', { ...item, _comment: value._id, callback: () => (showsReply = !showsReply) })" />
                </div>
                <v-divider />
            </div>
        </v-expand-transition>
    </li>
</template>

<script>
import CommentInput from "./comment-input.vue";
import CommentReply from "./comment-reply.vue";
export default {
    components: {
        CommentInput,
        CommentReply,
    },
    props: {
        value: {
            user: { type: Object },
            content: { type: String, default: "" },
            children: { type: Array, default: () => [] },
        },
        expanded: { type: Boolean, default: false },
    },
    data() {
        return {
            showsReply: this.expanded,
            isEdit: false,
        };
    },
    computed: {
        name() {
            let { name } = this.value.user || {};
            name = name.substr(0, 1) + "O" + name.substr(2);
            return name;
        },
        isUser() {
            return this.value?.user?._id == this.$store?.state?.payload?._user;
        },
        isLoggedIn() {
            return !!this.$store.state.payload?._user;
        },
        isRouted() {
            return this.$route.params._comment == this.value?._id;
        },
    },
    mounted() {
        if (this.isRouted) this.scrollToThis();
    },
    watch: {
        isRouted(value) {
            if (value) this.scrollToThis();
        },
    },
    methods: {
        onMention(comment) {
            if (this.showsReply && this.$refs["comment-input"]?.mention_comment == comment) {
                this.showsReply = false;
                return;
            }
            this.showsReply = true;
            this.$nextTick(() => this.$refs["comment-input"]?.onMention?.(comment));
        },
        scrollToThis() {
            this.$nextTick(() => setTimeout(() => window.scrollTo({ top: this.$refs["comment"]?.getBoundingClientRect?.()?.top - 96, behavior: "smooth" }), 500));
        },
    },
};
</script>
