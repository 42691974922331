<template>
    <li class="grey lighten-5" :class="{ 'light-blue lighten-5': isRouted }">
        <v-card ref="comment" color="transparent" tile :disabled="value?.isDeleted">
            <v-card-subtitle>
                <v-row class="mx-n1 my-n2" align="center">
                    <v-col cols="auto" class="pa-1 font-weight-bold">{{ value?.user?.name }} </v-col>
                    <v-col cols="auto" class="pa-1">{{ value?.createdAt?.toDateTime?.() }}</v-col>
                    <v-spacer />
                    <template v-if="isLoggedIn">
                        <v-col cols="auto" class="pa-1">
                            <v-btn @click="$emit('mention', value)" x-small text tile color="secondary" class="pa-1">
                                <span> 답글달기 </span>
                            </v-btn>
                        </v-col>
                    </template>
                    <template v-if="isUser">
                        <v-col cols="auto" class="pa-1">·</v-col>
                        <v-col cols="auto" class="pa-1">
                            <v-btn @click="isEdit = !isEdit" x-small text tile color="secondary" class="pa-1">
                                <span> 수정 </span>
                            </v-btn>
                        </v-col>
                        <v-col cols="auto" class="pa-1">·</v-col>
                        <v-col cols="auto" class="pa-1">
                            <v-btn @click="$emit('remove', value)" x-small text tile color="secondary" class="pa-1">
                                <span> 삭제 </span>
                            </v-btn>
                        </v-col>
                    </template>
                </v-row>
            </v-card-subtitle>

            <v-card-text>
                <comment-input v-if="isEdit" v-model="value" v-bind="{ isLoggedIn }" usesCustomActivator buttonText="수정" @save="(item) => ($emit('save', item) || true) && (isEdit = !isEdit)" />
                <template v-else-if="value.isDeleted"> 블라인드된 댓글입니다. </template>
                <template v-else>
                    <b v-if="value?.mention" class="primary--text mr-1">{{ value.mention }}</b>
                    {{ value?.content }}
                </template>
            </v-card-text>
        </v-card>
        <v-divider />
    </li>
</template>

<script>
import CommentInput from "./comment-input.vue";
export default {
    components: {
        CommentInput,
    },
    props: {
        value: {
            user: { type: Object, default: () => ({}) },
            content: { type: String, default: "" },
        },
    },
    data: () => ({
        isEdit: false,
    }),
    computed: {
        isUser() {
            return this.value?.user?._id == this.$store?.state?.payload?._user;
        },
        isLoggedIn() {
            return !!this.$store.state.payload?._user;
        },
        isRouted() {
            return this.$route.params._comment == this.value?._id;
        },
    },
    mounted() {
        if (this.isRouted) this.scrollToThis();
    },
    watch: {
        isRouted(value) {
            if (value) this.scrollToThis();
        },
    },
    methods: {
        scrollToThis() {
            this.$nextTick(() => setTimeout(() => window.scrollTo({ top: this.$refs["comment"]?.getBoundingClientRect?.()?.top - 96, behavior: "smooth" }), 500));
        },
    },
};
</script>

<style></style>
