<template>
    <div v-if="board">
        <v-simple-table class="v-data-table--default v-data-table--no-hover w-100">
            <tbody>
                <tr>
                    <th>
                        <span class="break-words">{{ board.subject }}</span>
                    </th>
                </tr>
                <tr>
                    <td>
                        <div class="divider-group">
                            <ul>
                                <li>작성자 : {{ board.writer.name }}</li>
                                <li>작성일 : {{ board.createdAt.toDate() }}</li>
                                <li>조회수 : {{ board.viewCount }}</li>
                            </ul>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="min-h-100px min-h-md-150px board-contents" v-html="board.content"></div>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        <comments :_board="board?._id" />

        <div class="btn-wrap">
            <v-row class="row--xs">
                <!-- <v-col v-show="isUserWriter" cols="auto">
                    <v-row class="row--xs">
                        <v-col cols="auto">
                            <v-btn outlined color="grey" @click="$emit('remove')">삭제</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn color="primary" @click="$router.push(`${$route.path}/modify`)">수정</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn outlined color="primary">답글</v-btn>
                        </v-col>
                    </v-row>
                </v-col> -->
                <v-spacer />
                <v-col cols="auto">
                    <v-btn outlined color="primary" @click="$router.go(-1)">리스트</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import CommentList from "../../comment-list.vue";
import Comments from "./comments.vue";

export default {
    components: {
        CommentList,
        Comments,
    },
    props: {
        board: { type: Object, default: null },
        isUserWriter: { type: Boolean, default: false },
    },
    data() {
        return {
            data: undefined,
            show: false,
        };
    },
    watch: {
        board() {
            if (this.board) this.show = true;
        },
    },
};
</script>