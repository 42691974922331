<template>
    <page-section class="page-section--sm">
        <div class="tit-wrap tit-wrap--sm">
            <h4 class="tit tit--xs">
                댓글
                <span class="primary--text">{{ summary?.totalCount }}</span>
            </h4>
        </div>
        <v-divider class="grey darken-4" style="border-width: 2px 0 0" />
        <comments :value="items" @save="save" @remove="remove" />
        <div class="pt-3">
            <comment-input v-bind="{ isLoggedIn }" relative @save="save" />
        </div>
    </page-section>
</template>

<script>
import api from "@/api";

import Comments from "@/components/dumb/comments/comments.vue";
import CommentInput from "@/components/dumb/comments/comment-input.vue";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        Comments,
        CommentInput,
        PageSection,
    },
    props: {
        _board: { type: String, default: null },
    },
    data: () => ({
        comments: [],
        summary: { totalCount: 0 },
    }),
    computed: {
        items() {
            const { parents = [], children = [] } = this.comments.map((comment) => ({ ...comment, user: comment.writer })).reduce(({ parents = [], children = [] }, comment) => (comment?._comment ? { parents, children: [...children, comment] } : { parents: [...parents, comment], children }), {});

            return (function makeSort(siblings, remained, depth = 0, result = []) {
                for (const parent of siblings) {
                    const children = remained.filter(({ _comment }) => _comment == parent._id);
                    const nextRemained = remained.filter(({ _comment }) => _comment !== parent._id);
                    result.push({ ...parent, depth, children: makeSort(children, nextRemained, depth + 1) });
                }
                return result;
            })(parents, children);
        },
        isLoggedIn() {
            return !!this.$store.state.payload?._user;
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        "summary.totalCount"() {
            this.$emit("syncComments", this.summary.totalCount);
        },
    },
    methods: {
        async search() {
            const { _board } = this;
            const { summary, comments } = await api.v1.boards.comments.gets({ _board });
            this.summary = summary;
            this.comments = comments;
        },
        async save({ callback = () => {}, ...item } = {}) {
            const isPut = !!item?._id;
            const { _board } = this;
            const { comment } = isPut ? await api.v1.boards.comments.put({ _id: item?._id, _board, content: item?.content, mention: item?.mention || null }) : await api.v1.boards.comments.post({ ...item, _board });

            if (isPut) {
                const index = this.comments.findIndex(({ _id }) => _id == item?._id);
                this.comments.splice(index, 1, comment);
            } else {
                this.comments = [...this.comments, comment];
                this.summary.totalCount += 1;
            }

            // alert("성공적으로 처리되었습니다");
            callback();
        },
        async remove(item) {
            if (confirm("삭제하시겠습니까?")) {
                await api.v1.boards.comments.delete(item);

                const targetIndex = this.comments.findIndex(({ _id }) => _id == item._id);
                this.comments.splice(targetIndex, 1);
                this.summary.totalCount -= 1;

                // alert("성공적으로 처리되었습니다");
            }
        },
    },
};
</script>

<style scoped></style>
