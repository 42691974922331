var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isMobile ? _c('v-bottom-sheet', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        }), !_vm.usesCustomActivator ? _c('div', _vm._g(_vm._b({
          class: `comment-input-wrap${_vm.relative ? ' relative' : ''}`
        }, 'div', attrs, false), on), [_c('div', {
          staticClass: "comment-input",
          staticStyle: {
            "pointer-events": "none"
          }
        }, [_c('v-text-field', _vm._b({
          staticClass: "comment-input__input",
          attrs: {
            "outlined": "",
            "hide-details": ""
          },
          model: {
            value: _vm.content,
            callback: function ($$v) {
              _vm.content = $$v;
            },
            expression: "content"
          }
        }, 'v-text-field', {
          disabled: _vm.disabled,
          placeholder: _vm.placeholder
        }, false), [_c('v-btn', _vm._b({
          staticClass: "v-btn--effect-none comment-input__btn",
          attrs: {
            "slot": "append",
            "text": "",
            "small": "",
            "color": "primary"
          },
          slot: "append"
        }, 'v-btn', {
          disabled: _vm.disabled
        }, false), [_vm._v(_vm._s(_vm.buttonText))])], 1)], 1)]) : _vm._e()];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('div', {
    staticClass: "comment-input-bottom-sheet"
  }, [_c('div', {
    staticClass: "comment-input"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', _vm._b({
    ref: "text-field",
    staticClass: "comment-input__input",
    attrs: {
      "outlined": "",
      "hide-details": "",
      "prefix": _vm.mention
    },
    on: {
      "keydown": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) return null;
        return _vm.removeMentionTo.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.emit.apply(null, arguments);
      }]
    },
    model: {
      value: _vm.content,
      callback: function ($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  }, 'v-text-field', {
    disabled: _vm.disabled,
    placeholder: _vm.placeholder
  }, false), [_c('v-btn', _vm._b({
    staticClass: "comment-input__btn",
    attrs: {
      "slot": "append",
      "text": "",
      "small": "",
      "color": "primary"
    },
    on: {
      "click": _vm.emit
    },
    slot: "append"
  }, 'v-btn', {
    disabled: _vm.disabled
  }, false), [_vm._v(_vm._s(_vm.buttonText))])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', {
    on: {
      "click": _vm.close
    }
  }, [_vm._v("mdi-close-circle")])], 1)], 1)], 1)])]) : _c('v-text-field', _vm._b({
    ref: "text-field",
    staticClass: "comment-input__input",
    attrs: {
      "outlined": "",
      "hide-details": "",
      "prefix": _vm.mention
    },
    on: {
      "keydown": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) return null;
        return _vm.removeMentionTo.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.emit.apply(null, arguments);
      }]
    },
    model: {
      value: _vm.content,
      callback: function ($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  }, 'v-text-field', {
    disabled: _vm.disabled,
    placeholder: _vm.placeholder
  }, false), [_c('v-btn', _vm._b({
    staticClass: "comment-input__btn",
    attrs: {
      "slot": "append",
      "text": "",
      "small": "",
      "color": "primary"
    },
    on: {
      "click": _vm.emit
    },
    slot: "append"
  }, 'v-btn', {
    disabled: _vm.disabled
  }, false), [_vm._v(_vm._s(_vm.buttonText))])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }